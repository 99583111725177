<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

import metatags from "./metatags";

export default {
  name: "app",
  metaInfo() {
    return {
      title: this.currentTitle,
      titleTemplate: "%s" + this.titleSuffix,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.currentDescription,
        },
        {
          itemprop: "name",
          content: this.currentFullTitle,
        },
        {
          itemprop: "description",
          content: this.currentDescription,
        },
        {
          name: "twitter:title",
          content: this.currentFullTitle,
        },
        {
          name: "twitter:description",
          content: this.currentDescription,
        },
        {
          name: "og:title",
          content: this.currentFullTitle,
        },
        {
          name: "og:description",
          content: this.currentDescription,
        },
        {
          name: "og:url",
          content: this.currentUrl,
        },
      ],
    };
  },
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      metatags: metatags,
      currentRouteName: this.$router.currentRoute.name,
      titleSuffix: " | Sewio Summit",
      currentUrl: window.location.origin + this.$router.currentRoute.path,
    };
  },
  watch: {
    $route(to) {
      this.currentRouteName = to.name;
      this.currentUrl = window.location.origin + to.path;
    },
  },
  computed: {
    currentTitle() {
      return this.metatags[this.currentRouteName].title;
    },
    currentDescription() {
      return this.metatags[this.currentRouteName].description;
    },
    currentFullTitle() {
      return this.currentTitle + this.titleSuffix;
    },
  },
  mounted() {
    axios.get(this.api + "agenda").then((response) => {
      this.$root.$data.days = response.data.days;
    });
    axios.get(this.api + "venue").then((response) => {
      this.$root.$data.venue = response.data.venue;
    });
  },
};
</script>

<style lang="scss">
@import "./scss/variables";

@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/base/_all";
@import "~bulma/sass/elements/_all";
@import "~bulma/sass/components/_all";
@import "~bulma/sass/grid/_all";
@import "~bulma/sass/layout/_all";

@import "~buefy/src/scss/utils/all";
@import "~buefy/src/scss/components/notices";

a {
      display: inline-block;
      color: #059b39;
      &:after {
          content: "";
          display: block;
          width: 100%;
          position: relative;
          bottom: 2px;
          height: 1px;
          background-color: #ebebeb;
          transition: background-color 0.4s ease-in-out;
      }
      &:hover:after {
          background-color: #059b39;
      }
      &:hover {
          color: #059b39;
      }
  }
</style>
