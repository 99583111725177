export default {
    homepage: {
        title: 'Where the innovation accelerates',
        description: 'Sewio Summit is an inspirational, educative and training event for innovation in RTLS, IoT, Digital Twin and Industry 4.0.'
    },
    agenda: {
        title: 'Agenda',
        description: 'Check out the agenda full of real-life uses cases that helps organizations to be more productive, cost-effective and safe.'
    },
    sessions: {
        title: 'Sessions',
        description: 'See the full list of topic that will be presented and discussed at Sewio Summit.'
    },
    venue: {
        title: 'Venue',
        description: 'Find out what we believe is a great place to learn, get inspired, network with others and have fun.'
    },
    register: {
        title: 'Registration',
        description: 'Register for Sewio Summit, the first RTLS conference in Europe where innovation accelerates.'
    },
    gallery: {
        title: 'Gallery',
        description: 'See the photos from Sewio Summit 2018.'
    }
}