<template>
  <div class="propositions">
    <div class="columns is-8">
            <div class="column">
                <value-proposition title="Case Studies" image="owl.svg" :is-local-image="true" description="Get inspired by innovative IoT projects delivered by the network of our 70+ global partners."></value-proposition>
            </div>
            <div class="column">
                <value-proposition title="A Tour to Volkswagen" image="bus.svg" :is-local-image="true" description="A long-awaited visit to a real-life RTLS project in one of the most modern facilities in the world."></value-proposition>
            </div>
            <div class="column"
      >
        <value-proposition
          title="Diverse Audience" image="cowboy.svg" :is-local-image="true" description="A mixed audience of partners, customers, analysts, journalists and industry experts at one location."></value-proposition>
            </div>
        </div>
        <div class="columns is-8">
            <div class="column">
                <value-proposition title="An Inspirational Place" image="tree.svg" :is-local-image="true" description="Away from the bustling city, surrounded by vineyards and orchards, yet close to the metropolises of Vienna, Bratislava and Brno."></value-proposition>
            </div>
            <div class="column">
                <value-proposition title="Meet the Team Behind" image="mask.svg" :is-local-image="true"
          description="We will bring half of our company, so you have the chance to meet them, ask questions and give feedback in person."></value-proposition>
            </div>
            <div class="column">
                <value-proposition title="Great Fun" image="barrel.svg" :is-local-image="true" description="A social networking event with sommelier-guided wine tasting and local music."
        ></value-proposition>
      </div>
    </div>
  </div>
</template>

<script>
import ValueProposition from "../molecules/ValueProposition";

    export default {
        name: "ValuePropositions",
        components: { ValueProposition }
    }
</script>

<style lang="scss">
.propositions {
  margin-top: 3rem;
  & > .columns {
    margin-bottom: 3rem;
  }
}
</style>