<template>
  <div>
    <Hero
      title="The First RTLS Conference in Europe"
      subtitle="The Dominant Winery House, Rakvice<br>October 24-25, 2018"
      content="Learn how you can push your business to the next level, make new connections, get inspired by real-life case studies and gain the industry knowledge and insights necessary to be ahead of the game. Join us at #SewioSummit where the innovation accelerates!"
        ></Hero>
        <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <br />
            <p class="title is-2">Need to convince your boss?</p>
            <p class="subtitle is-4">
              Here are some talking points to make your case.
            </p>
            <br />
          </div>
        </div>
        <value-propositions></value-propositions>
      </div>
    </div>
    <div class="venue-image-aspect-ratio">
      <img
        src="../../assets/venue.jpg"
        alt="Venue - Rakvice"
        class="venue-image"
      />
    </div>
  </div>
</template>

<script>
    import ValuePropositions from "../organisms/ValuePropositions";
    import Hero from "../common/Hero";

export default {
  name: "Homepage",
  components: { Hero, ValuePropositions},
};
</script>

<style lang="scss">
.venue-image-aspect-ratio {
        display: block;
        width: 100%;
        padding-bottom: 35%;
        position: relative;
    }
    .venue-image {
        position: absolute;

        left: 0;
        top: 0;
        bottom: 0;
        right: 0;

        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center 35%;
    }
</style>