<template>
  <div>
    <footer>
      <div class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-4">
              <p class="title is-size-5">
                Got a question? Don’t hesitate to <br />
                contact me.
              </p>
              <div class="columns footer--contact--info">
                <div class="column is-narrow">
                  <img
                    src="../../assets/passinger.jpeg"
                    alt="Petr Passinger"
                    class="footer--contact--image"
                  />
                </div>
                <div class="column">
                  <p>
                    <b>Petr Passinger</b> / CMO <br />
                    <a href="tel:+420777144172">(+420) 777 144 172</a><br />
                    <a href="mailto:petr.passinger@sewio.net"
                      >petr.passinger@sewio.net</a
                    ><br />
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-4 is-offset-4 has-text-right">
              <a
                href="https://www.sewio.net/?utm_source=sewio_summit_website&utm_medium=sewio_summit_website&utm_campaign=sewio_summit_website"
                target="_blank"
              >
                <img
                  src="../../assets/sewio_white.svg"
                  alt="Sewio Networks s.r.o."
                />
              </a>
              <br />
              <br />
              <p>
                <b>Sewio Networks s.r.o.</b><br />
                JIC INMEC, Purkynova 649/127 <br />
                612 00, Brno, Czech Republic <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <section class="footnote">
      <div class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-4">
              <p class="footnote--copyright is-size-7">
                Copyright 2018 Sewio Networks | All Rights Reserved
                            </p>
                        </div>
                        <div class="column is-4 is-offset-4 footnote--social-icons">
                            <a href="https://www.facebook.com/Sewio-441835469351356/" target="_blank"><font-awesome-icon :icon="['fab', 'facebook']" /></a>
                            <a href="https://twitter.com/SEWIOnet" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']" /></a>
                            <a href="http://www.youtube.com/c/Sewio" target="_blank"><font-awesome-icon :icon="['fab', 'youtube']" /></a>
                            <a href="https://www.linkedin.com/company/sewio-networks-s-r-o-?trk=top_nav_home" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']" /></a>
                            <a href="mailto:info@sewio.net" target="_blank"><font-awesome-icon :icon="['far', 'envelope']" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>
    export default {
        name: "Footer",
    }
</script>

<style lang="scss">
@import "../../scss/variables";
footer,
.footnote {
  a {
    &:after {
      content: none;
    }
  }
}
footer {
  background-color: $dark;
  color: $white;
  .title {
    color: $white;
    margin-bottom: 0 !important;
  }
  .columns {
    align-items: flex-start;
  }
  img {
    width: 12rem;
  }
  .footer {
    &--contact--info {
      margin-top: 2rem;
      a {
        color: $white;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &--contact--image {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
  }
}
.footnote {
  background-color: $darker;
  color: $dark-text;

  &--copyright {
  }

  &--social-icons {
    text-align: right;
    a {
      color: $white;
      &:hover {
        color: $dark-text;
      }
      padding-left: 1rem;
    }
  }
}
</style>