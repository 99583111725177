export default {
  en: {
    attributes: {
      first_name: "First name",
      last_name: "Last name",
      job_title: "Job title",
      company: "Company",
      email: "Business email",
      phone: "Phone",
      message: "Additional comments",
      consent: "Consent",
    },
    custom: {
      consent: {
        required: "Your consent is required.",
      },
    },
  },
};
