<template>
  <div class="proposition">
    <div class="columns">
      <div class="column">
        <div class="proposition--image">
          <img
            v-if="isLocalImage"
            :src="require(`@/assets/propositions/${image}`)"
            :alt="title"
          />
          <img v-else :src="image" alt="title" />
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-10">
        <p class="title is-4">{{ title }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-10">
        <p>
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValueProposition",
  props: {
    image: {
      type: String,
    },
    isLocalImage: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.proposition {
  margin-bottom: 3rem;
  &--image img {
    width: 64px;
  }
  .title {
    font-weight: normal;
  }
}
</style>
