<template>
  <div>
    <Hero title="Gallery" subtitle="Sewio Summit 2018 Photo Gallery"></Hero>
    <gallery-grid></gallery-grid>
  </div>
</template>

<script>
import ValuePropositions from "../organisms/ValuePropositions";
import Hero from "../common/Hero";
import GalleryGrid from "../molecules/GalleryGrid";

export default {
  name: "Gallery",
  components: { GalleryGrid, Hero, ValuePropositions },
};
</script>

<style lang="scss"></style>
